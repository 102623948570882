import React from 'react';
import Map from '../components/Map'

const InfoPage = () => (
  
  <div id="info">
    <h1>Info</h1>

    <h3>Location</h3>
    <address>
      2344 Webster St.<br />
      Oakland, CA 94612
    </address>
    <Map />
  </div>
);

 export default InfoPage;