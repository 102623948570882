import React from 'react';
import menuImg from '../img/menu.jpg';

const MenuPage = () => (
  <div id="menu">
    <h1>Menu</h1>
    <div className="container">
      <img src={menuImg} alt="Lovely's Menu" className="menu"/>
    </div>
  </div>
);

export default MenuPage;