import React from 'react'
import burger from '../img/burger.png';
const HomePage = () => (
  <div id="home">
    <h1 className="center-text">Lovely's</h1>
    <h2 className="center-text">2344 Webster St Oakland, CA</h2>
    <a href="/menu"><img id="lp-image"src={burger}></img></a>
  </div>
);

export default HomePage;