import React from 'react';
import notFoundImg from '../img/bon-apetit.jpg';

const NotFoundPage = () => (
  <div id="not-found">
    <h1>404: Page Not Found</h1>
    <div className="container">
      <img src={notFoundImg} alt="Lovely's burger"/>
    </div>
  </div>
);

export default NotFoundPage;