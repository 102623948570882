import React from 'react';

const Map = () => (
  <div className="mapouter">
    <div className="gmap_canvas">
      <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=2344%20Webster%20St.%20Oakland,%20CA%2094612&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
    </div>
  </div>
);

export default Map;