import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => (
  <>
    <footer className="footer py-3">
      <div className="container">
        <div className="row justify-content-start">
        <div className="col-4">
            <h5 className="logo">Lovely's</h5>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/menu">Menu</Link></li>
              <li><Link to="/info">Info</Link></li>
              <li><a href="https://www.instagram.com/lovelys__oakland/">Instagram</a></li>
            </ul>
          </div>
          <div className="col-4">
            <h5>Location</h5>
            <address>
              2344 Webster St<br></br>
              Oakland, CA 94612
            </address>
            
            <p><a href="tel:"></a></p>
          </div>
          <div className="col-4">
            

          </div>
        </div>
        <div className="footer-copyright">Copyright © 2023 Lovely's</div>
      </div>
      
    </footer>
  </>
);

export default Footer;