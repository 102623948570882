import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import MenuPage from './pages/MenuPage';
import InfoPage from './pages/InfoPage';
import NotFoundPage from './pages/NotFoundPage';
import { render } from '@testing-library/react';

class App extends React.Component{
  render() {
    return (
      <Router>
        <div className="App">
          <Navigation/>
          <main role="main" className="container flex-shrink-0">
            <Switch>
              <Route path="/" component={HomePage} exact />
              <Route path="/menu" component={MenuPage} />
              <Route path="/info" component={InfoPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </main>
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
